import React, { Component } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import Panel from '../Panel/Panel'
import Button from '../Button/Button'

class TalentpoolConfirmation extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const { headerText, bodyText, acceptLabel, declineLabel, onAccept, onDecline } = this.props

        return (
            <Container>
                <Row>
                    <Col>
                        <Panel className="panel--confirmation">
                            <h3>
                                <i className="panel__header__icon icons icon-people" /> {headerText}
                            </h3>
                            <p>{bodyText}</p>
                            <div className="multibutton__container">
                                <Button
                                    className="button--cta"
                                    buttonText={acceptLabel}
                                    onClick={onAccept}
                                    tabIndex="1"
                                />
                                <Button
                                    className="button--secondary"
                                    buttonText={declineLabel}
                                    onClick={onDecline}
                                    tabIndex="1"
                                />
                            </div>
                        </Panel>
                    </Col>
                </Row>
            </Container>
        )
    }
}

// Wrap the component to inject dispatch and state into it
export default TalentpoolConfirmation
